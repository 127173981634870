@media screen and (min-width: 900px) {
    .mainBody {
        display: flex;
        justify-content: flex-end;
        width: 95%;
        margin-top: 100px;
        background-color: #bacbbb;
        border-radius: 15px;
        border-width: 2px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 899px) {
    .mainBody {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 95%;
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
        background-color: #bacbbb;
        border-radius: 15px;
        border-width: 2px;
        margin-bottom: 30px;
    }
}


.picOfMe {
    width: calc(250px + 15vw);
    height: calc(360px + 20vw);
    padding: 0.67em;
    border-radius: 15px;
    border-width: 2px;
}

.mainText {
    margin-left: 5%;
    margin-right: 5%;
    font-size: calc(.8vw + 10px);
}