.mainBodySocial {
    margin-top: 100px;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 50px;
}

.videoPlayer {
    width: 100%;
    height: calc(12vw + 200px);
    border-radius: 15px;
}

@media screen and (min-width: 900px) {
    .socialContent {
        display: flex;
        justify-content: space-around;
        padding-bottom: 30px;

    }

    .concertBand {
        width: 100%;
    }


}

@media screen and (max-width: 899px) {
    .socialContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .concertBand {
        width: 95%;
    }
}