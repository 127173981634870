.mainBodyFirstYearHighlights {
    margin-top: 100px;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 50px;
}



@media screen and (min-width: 900px) {
    .firstYearHighlightsContent {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
    }

    .highlightPDF {
        width: 49.5%;
        height: 700px;
        padding-bottom: 10px;
    }

    .pdfAudio {
        display: flex;
        flex-direction: column;
        width: 49.5%;
    }

}

@media screen and (max-width: 899px) {
    .firstYearHighlightsContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .highlightPDF {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 80%;
        height: 600px;
    }
}