.mainBodyGamesApp {
    margin-top: 100px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
    background-color: #bacbbb;
    border-radius: 15px;
    border-width: 2px;
}

.joystick {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 52px;
}

.carouselButtons {
    display: flex;
    width: calc(50% + 17px); 
    margin-left: calc(50% - 17px);
    justify-content: space-between;
}

@media screen and (min-width: 900px) {

    .pics {
        width: 100%;
        border-radius: 12.5px 12.5px 0px 0px;
        max-height: 23vw;
    }

    .fullscreenImage {
        position: absolute;
        left: 10%;
        width: 80%;
        z-index: 7;
        background-color: #f2f7f4;
        border-radius: 15px;
    }

    .blocker {
        position: absolute;
        z-index: 6;
        width: 100vw;
        height: 105vh;
        background-color: black;
        opacity: 80%;
        top: 0;
    }

    .gamesAppContent {
        display: flex;
        justify-content: space-around;
    }

    .contentHalf {
        width: 48%;
    }

    .contentHalfImage {
        width: 48%;
        background-color: #f2f7f4;
        border-radius: 15px;
        border-width: 2px;
        filter: drop-shadow(3px 3px 4px gray);
        height: fit-content;
    }

    .MuiPaper-root {
        width: 49.5%;
        height: fit-content;
    }
}

@media screen and (max-width: 899px) {

    .pics {
        width: 100%;
        max-height: 43vw;
        border-radius: 12.5px 12.5px 0px 0px;
    }

    .fullscreenImage {
        position: fixed;
        top: 15%;
        width: 95%;
        left: 2.5%;
        z-index: 7;
        background-color: #f2f7f4;
        border-radius: 15px;
    }

    .blocker {
        position: absolute;
        z-index: 6;
        width: 100vw;
        height: 125vh;
        background-color: black;
        opacity: 80%;
        top: 0;
    }

    .gamesAppContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .contentHalfImage {
        width: 100%;
        background-color: #f2f7f4;
        border-radius: 15px;
        border-width: 2px;
        filter: drop-shadow(3px 3px 4px gray);
        height: fit-content;

    }

    .contentHalf {
        padding-top: 15px;
    }

}