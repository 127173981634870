.mainBodyForgeLaunch {
    margin-top: 100px;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 50px;
}

.contentBox {
    background-color: #bacbbb;
    border-radius: 15px;
    border-width: 2px;
}

.pics {
    width: 100%;
}

.forgeBlocker {
    position: fixed;
    z-index: 6;
    width: 100vw;
    height: 105vh;
    background-color: black;
    opacity: 80%;
    top: 0;
}

@media screen and (min-width: 900px) {
    .forgeLaunchContent {
        display: flex;
        justify-content: space-around;
    }

}

@media screen and (max-width: 899px) {
    .forgeLaunchContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}